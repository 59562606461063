var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return passes(_vm.submit)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":_vm.cols}},[_c('field',{ref:"fields",attrs:{"disabled":_vm.loading,"create":_vm.create,"inline":_vm.inline,"entity":_vm.entity,"table-definition":_vm.tableDefinition,"field":_vm.getField('partnercompany_id')}})],1),_c('b-col',{attrs:{"cols":"12","md":_vm.cols}},[_c('field',{ref:"fields",attrs:{"disabled":_vm.loading,"create":_vm.create,"inline":_vm.inline,"entity":_vm.entity,"table-definition":_vm.tableDefinition,"field":_vm.getField('partnergroup_is_internal')}})],1),_c('b-col',{attrs:{"cols":"12","md":_vm.cols}},[_c('field',{ref:"fields",attrs:{"disabled":_vm.loading,"create":_vm.create,"inline":_vm.inline,"entity":_vm.entity,"table-definition":_vm.tableDefinition,"field":_vm.getField('partnergroup_id')}})],1),_c('b-col',{attrs:{"cols":"12","md":_vm.cols}},[_c('field',{ref:"fields",attrs:{"disabled":_vm.loading,"create":_vm.create,"inline":_vm.inline,"entity":_vm.entity,"table-definition":_vm.tableDefinition,"field":_vm.getField('partnercompany_name')}})],1),_c('b-col',{attrs:{"cols":"12","md":_vm.cols}},[_c('field',{ref:"fields",attrs:{"disabled":_vm.loading,"create":_vm.create,"inline":false,"entity":_vm.entity,"table-definition":_vm.tableDefinition,"field":_vm.getField('address_id')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var subFormFields = ref.subFormFields;
var subTableDefinition = ref.subTableDefinition;
var subEntity = ref.subEntity;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('attribute.address_street') + '/' + _vm.$t('attribute.address_house_number'),"label-cols-md":"4"}},[_c('div',{staticClass:"d-flex"},[_c('field',{staticStyle:{"flex-grow":"1","margin-right":"20px"},attrs:{"inline":false,"disabled":_vm.disabled,"entity":subEntity,"table-definition":subTableDefinition,"field":_vm.getAddressFields(subFormFields).find(function (f){ return f.key==='address_street'; })}}),_c('field',{staticStyle:{"width":"100px"},attrs:{"inline":false,"disabled":_vm.disabled,"entity":subEntity,"table-definition":subTableDefinition,"field":_vm.getAddressFields(subFormFields).find(function (f){ return f.key==='address_house_number'; })}})],1)])],1)],1),_c('b-row',[_c('b-col',{attrs:{"col":"12","md":_vm.cols}},[_c('field',{attrs:{"inline":false,"disabled":_vm.disabled,"entity":subEntity,"table-definition":subTableDefinition,"field":_vm.getAddressFields(subFormFields).find(function (f){ return f.key==='city_id'; })},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var subFormFields = ref.subFormFields;
var subTableDefinition = ref.subTableDefinition;
var subEntity = ref.subEntity;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('attribute.city_zip') + '/' + _vm.$t('attribute.city_name'),"label-cols-md":"4"}},[_c('div',{staticClass:"d-flex"},[_c('field',{staticStyle:{"margin-right":"20px","width":"120px"},attrs:{"inline":false,"disabled":_vm.disabled,"entity":subEntity,"table-definition":subTableDefinition,"field":_vm.getCityFields(subFormFields).find(function (f){ return f.key==='city_zip'; })}}),_c('field',{staticStyle:{"flex-grow":"1"},attrs:{"inline":false,"disabled":_vm.disabled,"entity":subEntity,"table-definition":subTableDefinition,"field":_vm.getCityFields(subFormFields).find(function (f){ return f.key==='city_name'; })}})],1)])],1)],1)]}}],null,true)})],1)],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":_vm.cols}},[_c('field',{ref:"fields",attrs:{"disabled":_vm.loading,"create":_vm.create,"inline":_vm.inline,"entity":_vm.entity,"table-definition":_vm.tableDefinition,"field":_vm.getField('contactdetails_id')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var subFormFields = ref.subFormFields;
var subTableDefinition = ref.subTableDefinition;
var subEntity = ref.subEntity;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":_vm.cols}},[_c('field',{attrs:{"inline":_vm.inline,"disabled":_vm.disabled,"entity":subEntity,"table-definition":subTableDefinition,"field":subFormFields.find(function (f){ return f.key==='contactdetails_email'; })}})],1),_c('b-col',{attrs:{"cols":"12","md":_vm.cols}},[_c('field',{attrs:{"inline":_vm.inline,"disabled":_vm.disabled,"entity":subEntity,"table-definition":subTableDefinition,"field":subFormFields.find(function (f){ return f.key==='contactdetails_phone'; })}})],1),_c('b-col',{attrs:{"cols":"12","md":_vm.cols}},[_c('field',{attrs:{"inline":_vm.inline,"disabled":_vm.disabled,"entity":subEntity,"table-definition":subTableDefinition,"field":subFormFields.find(function (f){ return f.key==='contactdetails_fax'; })}})],1)],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":_vm.cols}},[_c('field',{ref:"fields",attrs:{"disabled":_vm.loading,"create":_vm.create,"inline":_vm.inline,"entity":_vm.entity,"table-definition":_vm.tableDefinition,"field":_vm.getField('companydetails_id')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var subFormFields = ref.subFormFields;
var subTableDefinition = ref.subTableDefinition;
var subEntity = ref.subEntity;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":_vm.cols}},[_c('field',{attrs:{"inline":_vm.inline,"disabled":_vm.disabled,"entity":subEntity,"table-definition":subTableDefinition,"field":subFormFields.find(function (f){ return f.key==='companydetails_website'; })}})],1)],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":_vm.cols}},[_c('field',{ref:"fields",attrs:{"disabled":_vm.loading,"create":_vm.create,"inline":_vm.inline,"entity":_vm.entity,"table-definition":_vm.tableDefinition,"field":_vm.getField('partnercompany_type')}})],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }